.car_specs {
    display: flex !important;
}

.tech_sp {
    font-weight: bold;
    font-size: 38px;
    margin-left: 16px;
}

.car_details {
    padding: 16px 0 10px 36px;
    font-size: 18px;
    font-family: "Porsche Next", sans-serif;
    font-weight: bold;
    width: 50%;
}

.car_inform {
    width: 700px;
    max-width: 768px;
    margin-top: 15em;
    position: relative;
    margin-left: 1rem;
}

.car_inform svg {
    transform: rotate(90deg);
}

.bold_text {
    font-weight: bold;
    font-size: 24px;
}

.top_left {
    position: absolute;
    top: -10%;
    left: 16px;
    width: 170px;
}

.top_right {
    position: absolute;
    top: -10%;
    right: 16px;
    width: 170px;
}

.bottom_right {
    position: absolute;
    bottom: 75%;
    right: 16px;
    width: 170px;
}

.bottom_left {
    position: absolute;
    bottom: 75%;
    left: 16px;
    width: 170px;
}

.details_group {
    padding: 10px 16px 0 16px;
}

@media screen and (max-width: 565px) {
    .car_specs {
        display: flex;
        flex-direction: column-reverse;
    }

    .car_inform {
        margin-top: 0 !important;
        margin-left: 0 !important;
        padding-top: 25px !important;
        display: flex;
        flex-direction: row;
        max-width: 100% !important;
    }

    .car_details {
        padding-left: 0 !important;
        width: 100% !important;
    }

    .car_inform svg {
        width: 100%;
        height: 400px;
    }

    .top_left, .top_right, .bottom_left, .bottom_right {
        position: static !important;
        width: 100% !important;
    }
}