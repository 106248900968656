.models {
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;
    align-content: flex-start;
    margin-top: 12px;
    margin-left: 60px;
    margin-right: 0;
}
.model img {
    width: 300px;
}

@media screen and (max-width: 565px){
    .models {
        display: block !important;
        margin-left: 0;
    }
    .model img {
        width: 100%;
    }
    .model p {
        margin-bottom: 0;
    }
    .car_list h3 {
        text-align: center;
    }

}