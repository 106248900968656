.header {
    overflow: hidden;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header_menu {
    float: left;
    cursor: pointer;
    margin-left: 8px;
    margin-top: 8px;
    width: 24px;
    height: 24px;
}

.header_logo {
    display: block;
    margin-top: 14px;
    max-width: 240px;
    max-height: 16px;
}

.header_search {
    float: right;
    cursor: pointer;
    margin-right: 8px;
    margin-top: 8px;
    width: 24px;
    height: 24px;
}

@media screen and (max-width: 768px) {
    .Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .Header-menu {
        float: left;
        cursor: pointer;
        margin-left: 8px;
        margin-top: 8px;
        width: 24px;
        height: 24px;
    }

    .Header-logo {
        display: block;
        margin-top: 14px;
        max-width: 240px;
        max-height: 16px;
    }

    .Header-search {
        float: right;
        cursor: pointer;
        margin-right: 8px;
        margin-top: 8px;
        width: 24px;
        height: 24px;
    }
}