.tech_sp_hl {
    font-weight: bold;
    font-size: 38px;
    margin-left: 36px;
}

.highlight_card {
    padding: 24px 18px 18px 18px;
    font-size: 16px;
    font-family: "Porsche Next", sans-serif;
    float: left;
    width: 20%;
    border-radius: 0 !important;
    border: 0 !important;
}

.highlights_group {
    padding: 10px 16px 0 16px;
}

.card_group {
    display: flex;
    flex-flow: row wrap;
}


@media screen and (max-width: 575px) {
    .card_group .highlight_card {
        flex: 0 0 100%;
        max-width: 100%;
        border-radius: 0 !important;
        border: 1px solid #f5f0f0 !important;
    }

    .card_group {
        display: flex;
        flex-wrap: wrap;
    }

    .tech_sp_hl {
        margin-left: 16px;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .card_group .highlight_card {
        flex: 0 0 50%;
        max-width: 100%;
    }

    .card_group {
        display: flex;
        flex-wrap: wrap;
    }
}

